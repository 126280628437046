import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles/Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light sticky-top shadow-lg" style={{ backgroundColor: '#1C7464' }}>
            <div className="container">
                <a className="navbar-brand" href="#">
                    <LazyLoadImage src="images/logo.webp" alt="Kristie Aesthetic Clinic" style={{ height: '40px' }} effect="blur" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><a className="nav-link text-white" href="#about-us">About Us</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#best-treatment">Best Treatment</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#promotions">Promotions</a></li>
                        <li className="nav-item"><a className="nav-link text-white" href="#our-clinic">Our Clinic</a></li>
                    </ul>
                    <a href="https://wa.me/628112674355">
                        <LazyLoadImage src="images/konsul3.webp" alt="Kristie Aesthetic Clinic" className="navbar-right-logo" effect="blur" />
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
