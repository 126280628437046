import React from "react";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Promotions = () => {
  return (
    <section
      id="promotions"
      className="promotions py-5 text-center"
      style={{ backgroundImage: "url(/images/bg5.webp)" }}
    >
      <div className="container basic-color">
        <h1>
          <strong>Best Promo This Month</strong>
        </h1>
        <Carousel className="mt-4">
          <Carousel.Item>
            <div className="row">
              <div className="col-md-4">
                <LazyLoadImage
                  src="/promo/september/ads-1.jpg"
                  className="img-fluid mb-3"
                  alt="Promo 1"
                  effect="blur"
                />
              </div>
              <div className="col-md-4">
                <LazyLoadImage
                  src="/promo/september/ads-2.jpg"
                  className="img-fluid mb-3"
                  alt="Promo 2"
                  effect="blur"
                />
              </div>
              <div className="col-md-4">
                <LazyLoadImage
                  src="/promo/september/ads-3.jpg"
                  className="img-fluid mb-3"
                  alt="Promo 3"
                  effect="blur"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};

export default Promotions;
