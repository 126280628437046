import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const AboutUs = () => {
    return (
        <section id="about-us" className="about-us py-5" style={{ backgroundImage: 'url(/images/bg4.webp)' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-lg-1 order-2 mt-4 mt-lg-0 basic-color">
                        <h1><strong>Kristie Aesthetic Clinic</strong></h1>
                        <p className="mt-5">Kristie Aesthetic Clinic berkembang dari kolaborasi ide para pendiri yang merupakan dokter spesialis kulit & dokter spesialis farmakologi, hadir di Indonesia sejak tahun 2014. Klinik ini didasari konsep produk perawatan kulit berkualitas, didukung laser berteknologi modern, serta pelayanan profesional dari setiap staf. Kami memberikan pelayanan terbaik bagi para pelanggan yang memiliki kebutuhan perawatan kulit berbeda-beda dengan harga yang menarik.</p>
                        <p>Seluruh prosedur ditangani langsung oleh dokter ahli estetik berpengalaman & bersertifikat. Kami senantiasa meningkatkan kualitas produk dan pelayanan klinik.</p>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-1">
                        <LazyLoadImage src="/images/model2.webp" className="img-fluid" alt="About Us" effect="blur" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
