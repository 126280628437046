import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./styles/Header.css";

const Header = () => {
  return (
    <header
      className="header text-white py-md-5 position-relative d-md-flex flex-column justify-content-center"
      style={{
        backgroundImage: "url(/images/bg1.webp)",
        overflowY: "hidden",
        minHeight: 600,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-1 order-2 mt-4 mt-lg-0 basic-color">
            <h1 className="header-title">
              <span className="header-subtitle">
                Percayakan Cantikmu, Bersama
              </span>
              <strong> Kristie Aesthetic Clinic</strong>
            </h1>
            <p className="header-text mt-md-5 mt-3">
              Dapatkan promo menarik untuk Facial & Laser Treatment*
            </p>
            <a href="https://wa.me/628112674355">
              <LazyLoadImage
                src="/images/konsul1.webp"
                className="img-fluid mt-md-5 mt-3 btn-img"
                alt="Konsultasi Sekarang"
                effect="blur"
              />
            </a>
          </div>
          <div className="col-lg-6 order-lg-2 order-1">
            <div className="position-absolute" style={{ bottom: -20 }}>
              <img
                src="/images/model1.webp"
                className="img-fluid header_human"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
