import React from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import BestTreatment from './components/BestTreatment';
import Promotions from './components/Promotions';
import OurClinic from './components/OurClinic';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';

function App() {
    return (
        <div className="App">
            <Navbar />
            <Header />
            <AboutUs />
            <BestTreatment />
            <Promotions />
            <OurClinic />
            <Footer />
        </div>
    );
}

export default App;
