import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import KontakAsset8 from "../assets/KONTAK/LANDING PAGE KRISTIE_ Artboard 8.jpg";
import KontakAsset9 from "../assets/KONTAK/LANDING PAGE KRISTIE_Artboard 9.jpg";
import KontakAsset10 from "../assets/KONTAK/LANDING PAGE KRISTIE_Artboard 10.jpg";
import { Carousel } from "react-bootstrap";

const OurClinic = () => {
  return (
    <section
      id="our-clinic"
      className="our-clinic py-5"
      style={{ backgroundImage: "url(images/bg3.webp)" }}
    >
      <div className="container">
        <div className="row align-items-center basic-color">
          <div className="col-lg-6 order-lg-1 order-2 mt-4 mt-lg-0">
            {/* <Carousel>
              <Carousel.Item interval={1000}>
                <div className="">
                  <img src={KontakAsset8} className="img-fluid" alt="" />
                </div>
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <div className="">
                  <img src={KontakAsset9} className="img-fluid" alt="" />
                </div>
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <div className="">
                  <img src={KontakAsset10} className="img-fluid" alt="" />
                </div>
              </Carousel.Item>
            </Carousel> */}
            <h1>
              <strong>Our Clinic</strong>
            </h1>
            <p className="mt-5">
              Kami berkomitmen untuk memahami anda sebagai individu eksklusif
              dalam menjalani perawatan kulit dan mencapai hasil yang
              diharapkan.
            </p>
            <p>
              Untuk menjalankan misi tersebut, kami memiliki lebih dari 100
              varian produk perawatan kulit yang teruji aman dan terdaftar
              BPOM*, alat laser modern, berbagai jenis suntikan kulit, dan
              dokter berpengalaman estetik medis di klinik.
            </p>
          </div>
          <div className="col-lg-6 order-lg-2 order-1">
            <LazyLoadImage
              src="images/our.webp"
              className="img-fluid"
              alt="Our Clinic"
              effect="blur"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClinic;
