import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BestTreatment = () => {
    return (
        <section id="best-treatment" className="best-treatment py-5 text-center" style={{ backgroundImage: 'url(images/bg2.webp)' }}>
            <div className="container basic-color">
                <h1><strong>Best Treatment</strong></h1>
                <div className="row mt-4">
                    <div className="col-md-4">
                        <LazyLoadImage src="images/best1.webp" className="img-fluid mb-3" alt="Laser" effect="blur" />
                        <h3><strong>Laser</strong></h3>
                        <p>Treatment laser adalah perawatan medis dengan menggunakan alat yang berfokus terhadap cahaya. Cahaya yang difokuskan ini memiliki kekuatan yang sangat baik untuk perawatan dan pengobatan berbagai lesi dan penyakit kulit.</p>
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage src="images/best2.webp" className="img-fluid mb-3" alt="Facial" effect="blur" />
                        <h3><strong>Facial</strong></h3>
                        <p>Facial treatment merupakan bentuk perawatan wajah dengan teknik pembersihan kulit secara mendalam, pengelupaskan kulit kotor, serta melembabkan kulit dengan menggunakan masker oleh tenaga professional.</p>
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage src="images/best3.webp" className="img-fluid mb-3" alt="3D Shapping" effect="blur" />
                        <h3><strong>3D Shapping</strong></h3>
                        <p>Rangkaian perawatan seperti treatment Botulinum Toxin, Filler dan Threadlift untuk bentuk wajah lebih ideal & awet muda.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BestTreatment;
