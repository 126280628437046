import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Footer = () => {
    return (
        <footer className="footer text-white py-4" style={{ backgroundColor: '#1C7464' }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <LazyLoadImage src="images/logo.webp" alt="Kristie Aesthetic Clinic" style={{ height: '40px' }} effect="blur" />
                        <div className="mt-4">
                            <a href="https://wa.me/628112674355" className="text-white me-2">
                                <LazyLoadImage src="images/footer.webp" alt="Instagram" className="footer-icon" effect="blur" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 text-md-end">
                        <p className="mb-1">KONTAK</p>
                        <p className="mb-0">Whatsapp : <a href="https://wa.me/628112674355" className="text-white">+62 811-2674-355</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
